/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container {
  padding: 0 24px 0 24px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  row-gap: 1em;
  flex: 1;
}

.input {
  grid-row: 1;
  grid-column: 1;
}

.result-container {
  grid-row: 2;
  grid-column: 1;
}
.inner-result-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6em 0 0.6em 0;
  gap: 1em;
  overflow-y: scroll;
}
.result {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.8em;
  cursor: pointer;
}
.icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  background-color: var(--bg-color-dark2);
  fill: white;
}

.icon-role-container {
  background-color: var(--bg-color-dark2);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.icon-role {
  border-radius: 24px;
  fill: white;
  width: 45px;
  height: 45px;
  position: relative;
  top: 4px;
  left: 2px;
}

.profile {
  width: 48px;
  height: 48px;
}
.result-text-container {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}
.name {
  overflow: hidden;
  flex-shrink: 1;
}
.role {
  color: var(--fg-color-greyed);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.name-input-container {
  padding-left: 24px;
  padding-right: 24px;
}

.title {
  font-size: large;
  font-weight: bold;
  padding: 0;
  margin: 0 0 8px 24px;
}

.chat-partners-container-motion {
  display: flex;
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 4px;
  border-radius: 8px;
  padding-top: 16px;
  overflow-y: hidden;
  overflow-x: scroll;
  gap: 10px;
}

.chat-partner-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-partner-name {
  text-align: center;
  font-size: 9px;
  width: 65px;
  text-overflow: ellipsis;
  padding: 0;
  margin: 4px 0 0;
  hyphens: auto;
}

.search-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group_chat_title_selection_description {
  line-height: var(--line-height-desc);
}

.fab-container {
  position: absolute;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 12px;
  bottom: 0;
  width: 100%;
  background-color: var(--fg-color-dark1),
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 24px;
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  width: 110px
}
