/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 250px;
}

.stats-table {
  --col-base-spacing: 0.25em;

  color: var(--text-color-secondary);
  border-spacing: 0;
  white-space: nowrap;
  width: 100%;
}
.stats-row-total {
  font-weight: bold;
  color: var(--text-color);
}
.stats-cell-name {
  text-align: left;
  padding-right: calc(2 * var(--col-base-spacing));
}
.stats-cell-values-quota {
  text-align: right;
  padding-left: calc(2 * var(--col-base-spacing));
  padding-right: 0;
}
.stats-cell-values-current {
  text-align: right;
  padding-left: calc(1 * var(--col-base-spacing));
  padding-right: calc(1 * var(--col-base-spacing));
}
.stats-cell-values-arriving {
  text-align: right;
  padding-left: calc(1 * var(--col-base-spacing));
  padding-right: 0;
}
.stats-cell-values-middle {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.stats-cell-values-leaving {
  text-align: left;
  padding-left: 0;
  padding-right: calc(1 * var(--col-base-spacing));
}
.stats-cell-values-l {
  text-align: left;
}
.stats-cell-values-c {
  text-align: center;
}
.stats-cell-values-r {
  text-align: right;
}

.list-container {
  display: flex;
  flex-direction: row;
  column-gap: 0.3em;
}
.list-container div {
  overflow-wrap: unset;
}

.warning {
  color: var(--fg-color-warning);
}

.normal {
  color: var(--text-color-secondary);
}

.title {
  margin-bottom: 0.5em;
  font-size: small;
}

/* Left align text for the first column */
.customTable th.leftAligned,
.customTable td.leftAligned {
  text-align: left;
}

/* Right align text for the second column */
.customTable th.rightAligned,
.customTable td.rightAligned {
  text-align: right;
}
