.container {
  display: flex;
  justify-items: center;
  justify-content: space-between;
}

.range-picker {
  border: 1px solid rgba(48, 56, 89, 1);
  height: 100%;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
