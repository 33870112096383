/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.grid-container {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0 0;
  grid-template-areas:
    "empty header-content"
    "menu header-content";
  overflow: hidden;

  /*--cluster-spacer: 0.6em;*/
  --fine-line-width: 0.5px;
  --line-height: 18px;
  --corner-radius: 4px;
  --xlarge-text: 24px;
  --large-text: 16px;
  --mlarge-text: 14px;
  --medium-text: 12px;
  --small-text: 10px;
  --text-weight: 600;
  --blue-grey: #303859;
}

.menu {
  grid-area: menu;
  display: grid;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.header-content {
  grid-area: header-content;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
  overflow-x: scroll;
  position: relative;
  -ms-overflow-style: none;  /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
  cursor: grab;
}

.header-content::-webkit-scrollbar {
  display: none;
}

.header {
  grid-area: header;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  grid-area: content;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.empty {
  position: relative;
  grid-area: empty;
  overflow: hidden;
  opacity: 1;
  font-size: var(--xlarge-text);
  display: flex;
  flex-direction: column;
}

.scroll-bar {
  position: absolute;
  bottom: 0;
  height: 6px;
  border-radius: 6px;
  background-color: var(--scrollbar-background);
  cursor: pointer;
}


/*
-----------------------------------------------------------------------------------------------
 */


.times {
  font-size: var(--large-text);
  line-height: 160%;
  position: relative;
  grid-column: 1;
  grid-row: 3;
}

.time {
  position: absolute;
  width: 100%;
  text-align: right;
  color: var(--fg-color-greyed);
  padding-top: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.time-lines {
  position: relative;
  grid-column: 1;
  grid-row: 3;
  width: 100%;
}
.time-line {
  position: absolute;
  width: 100%;
  border-top: 1px dashed var(--fg-color-greyed);
}
.time-line-soft {
  position: absolute;
  width: 100%;
  opacity: 0.5;
  border-top: 0.5px dashed var(--fg-color-greyed);
}
.target-time-line {
  position: absolute;
  left: -20px;
  right: 0;
  border-top: 1px dashed var(--fg-color-greyed);
  /*transform: scaleX(2);*/
}
.target-icon {
  position: absolute;
  left: 40px;
  stroke: var(--fg-color-greyed);
  fill: var(--fg-color-greyed);
  transform: translate(0, -50%);
}

.current-time-container {
  position: relative;
  grid-row: 3;
  grid-column: 2;
  overflow: hidden;
}
.current-time-line {
  position: absolute;
  left: 3px;
  width: 100%;
  /*border-top: 2px solid var(--fg-color-frame);*/
  height: 8px;
  /*background-color: var(--fg-color-frame);*/
  transform: translate(0, -50%);
}

.current-time-line-fill {
  width: 100%;
  height: 3px;
  background-color: rgba(28, 173, 228, 0.2);
}

.current-time-line-center {
  width: 100%;
  height: 2px;
  background-color: #126f8d;
}

.current-time-indicator {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: var(--fg-color-frame);
  transform: translate(0, -4px);
  z-index: 20;
}

.or-area {
  grid-row: 1;
  background-color: var(--bg-color-dark1);
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.cluster-header {
  grid-row: 1;
  background-color: var(--bg-color-dark3);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  border-radius: 6px;
}
.or-header {
  grid-row: 2;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  font-size: var(--xlarge-text);
  line-height: 90%;
  padding: 0 0;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  cursor: pointer;
}

.or-items-outer {
  grid-row: 2;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.or-items {
  position: relative;
  margin: 0 8px;
}

.or-item-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.motion:before {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
}

.start-displayed-header {

}
