/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
}

.header {
  display: flex;
  flex-direction: row;
  font-size: large;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  height: 76px;
}
.header-button {
  width: 48px;
}

.dashboard {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
}

.text-grayed {
  color: var(--fg-color-greyed);
  width: 90px;
}

.font-bold-space {
  font-weight: bold;
  width: 110px;
}

.date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  color: var(--fg-color-greyed);
}

.box-number-2 {
  width: 22px;
}

.right-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}

.event-playback-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  margin-right: 3em;
}
