body {
  background-color: var(--bg-color-dark1);
  overflow: auto;
}

.app-full {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  grid-template-areas:
    'top-status top-status'
    'menu full-width';
}

.menu {
  grid-area: menu;
  background-color: var(--bg-color-dark1);
  z-index: 2;
}

.full-width {
  grid-area: full-width;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.app-frame {
  min-width: 320px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'top-status'
    'messenger';
}

.messenger-wrapper {
  width: 366px;
  background-color: var(--bg-color-dark1);
  z-index: 2;
}
@media screen and (max-width: 600px) {
  .messenger-wrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .app-frame-w-dashboard {
    display: grid;
    /*min-width: 900px;*/
    min-height: 400px;
    width: 100%;
    height: 100%;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'top-status top-status'
      'messenger dashboard';
  }
}
@media (min-width: 1200px) {
  .app-frame-w-dashboard {
    display: grid;
    min-width: 900px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'top-status top-status'
      'messenger dashboard';
  }
}
.app-frame-dashboard {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'dashboard';
}

.app-dashboard {
  width: 100%;
  height: 100%;
  grid-area: dashboard;
}

.app-messenger {
  grid-area: messenger;
  height: 100%;
  min-height: 0;
  display: flex;
  position: relative;
}

.app-messenger-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color-dark1);
  z-index: 202;
}

.app-messenger-toggle {
  position: absolute;
  right: -25px;
  height: 100%;
  z-index: 201;
}

@media (max-width: 600px) {
  .app-messenger-menu {
    display: none;
  }
  .app-messenger-toggle {
    display: none;
  }
}

.top-status {
  grid-area: top-status;
}
