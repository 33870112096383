/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.description-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
}
.description-icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
.description {
  font-size: medium;
  cursor: default;
}
.long-description {
  font-size: medium;
  cursor: default;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.location-text {
  font-size: medium;
  padding: 0;
  margin: 0;
}

.checklist {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checklist-icon-container {
  display: grid;
}
.checklist-icon {
  grid-row: 1;
  grid-column: 1;
  fill: var(--fg-color-bright);
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
.checklist-badge {
  grid-row: 1;
  grid-column: 1;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border-style: solid;
  border-color: var(--bg-color-dark1);
  border-width: 2.5px;
  transform: translate(13px, -4px);
}
.red {
  background-color: var(--traffic-light-red);
}
.yellow {
  background-color: var(--traffic-light-yellow);
}
.green {
  background-color: var(--traffic-light-green);
}
.initial {
  background-color: #808080;
}
.next-icon {
  fill: var(--fg-color-frame);
  stroke: var(--fg-color-frame);
  width: 32px;
  height: 32px;
}
