/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  margin: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.6em;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 12%;
}

.clinic-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6pt;
}
.nursing-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nursing-container-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nursing-container-descriptions {
  color: var(--fg-color-greyed);
  font-size: smaller;
  margin-top: 0.3em;
  margin-left: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'area';
}
.nursing-container-descriptions-inner {
  grid-area: area;
  visibility: hidden;
  hyphens: auto;
}
.nursing-container-descriptions-inner-visible {
  visibility: visible;
}

.title {
  font-size: large;
}

.clinic {
  min-width: 10em;
}

.button-container {
  margin: 0em 1.2em 1.2em 1.2em;
}

.priority-description {
  color: var(--fg-color-greyed);
  font-size: smaller;
  margin-top: 0.3em;
  margin-left: 1em;
}
