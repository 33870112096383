.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.chart-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.chart-position {
  position: absolute;
  top: -15px;
  bottom: -15px;
  right: -10px;
  left: 0;
}
