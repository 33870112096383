.container {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: bold;
}

.warning {
  color: var(--fg-color-warning);
}
.normal {
  color: white;
}
