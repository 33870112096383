/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  margin-left: 1.2em;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5em;
  height: 25px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.clinic {
}

.yes {
  color: var(--fg-color-frame);
}

.no {
  color: var(--fg-color-bright);
}

.table {
  font-size: medium;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
}

.tr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
}

.value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
