/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  margin-left: 1.2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.active-text {
  margin-left: 1.2em;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--fg-color-frame);
}
.problem-text {
  margin-left: 3px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--fg-color-warning);
}
.comment-text {
  margin-left: 1.2em;
  margin-bottom: 6px;
  color: var(--fg-color-greyed);
}
.icon-affirmative-text {
  margin-left: 4px;
  margin-bottom: 6px;
  color: var(--fg-color-frame);
}
.icon-challenged-text {
  margin-left: 4px;
  margin-bottom: 6px;
  color: var(--fg-color-warning);
}
.add-text {
  margin-left: 0.5em;
  color: var(--fg-color-frame);
}
.button {
  min-width: 260px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.custom-container {
  width: 280px;
  margin: 0 0 1em 0;
}

.icon-container {
  flex-shrink: 0;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.icon {
  stroke: var(--fg-color-bright);
  fill: var(--fg-color-bright);
}
.active-icon {
  stroke: var(--fg-color-frame);
  fill: var(--fg-color-frame);
}
.problem-icon {
  flex-shrink: 0;
  fill: var(--fg-color-warning);
}
.doneall-icon-small {
  flex-shrink: 0;
  fill: var(--fg-color-frame);
  color: var(--fg-color-frame);
  scale: 0.8;
  margin-left: 0.7em;
  transform: translateY(-3px);
}
.problem-icon-small {
  flex-shrink: 0;
  color: var(--fg-color-warning);
  fill: var(--fg-color-warning);
}

.line {
  flex: 1;
  border-left-color: var(--fg-color-bright);
}
.active-line {
  flex: 1;
  border-left-color: var(--fg-color-frame);
}
.problem-line {
  flex-shrink: 0;
  margin: 0 4px;
  width: 1.5em;
  border-top-color: var(--fg-color-warning);
}
