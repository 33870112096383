/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  gap: 0.3em;
  height: 2em;
  margin-left: 15px;
}

.progress-checked {
  display: flex;
  align-items: center;
  gap: 0.3em;
}
