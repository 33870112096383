.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--fg-color-bright);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.result {
  font-size: 14px;
  color: var(--fg-color-greyed);
  font-weight: bold;
}

.label-warning {
  color: #ffc000;
}
