.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-bottom: 1px solid var(--border-color);
  padding: 10px;
  gap: 15px;
}

.multiselect-button {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 45px;
  border: 2px solid #242A42;
  background-color: #121930;
  border-radius: 10px;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 15px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  cursor: pointer;
}
