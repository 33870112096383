/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.or-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
}

.cut-container {
    display: flex;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    right: -30px;
    bottom:0;
    width:  30px;
    height: 100%;
    flex-direction: row;
    z-index: 20;
}

.n-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width:  2px;
    /*border: 1px dashed #a4ccda;*/
}

.s-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  width:  10px;
  /*border: 1px dashed rgba(255, 7, 7, 0.55);*/
}

.fillerNTop {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 50%;
    width:  100%;
    /*border: 1px dashed #4f77e3;*/
}

.fillerNBottom {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    height: 50%;
    width:  100%;
    /*border-right: 2px solid #303859;*/
    /*border: 1px dashed #4f77e3;*/
}

.fillerSTop {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    right: 2px;
    background-color: rgba(18, 25, 48, 0.13);
}

.fillerSBottom {
  position: relative;
  height: 50%;
  width:  100%;
  flex-shrink: 0;

  /*border: 1px dashed #f861dd;*/
}


.or-item-background {
    background-color: rgba(18, 25, 48, 0.9);
    /*border-radius: var(--corner-radius);*/
}

.or-item-marker {
    position: absolute;
    width: 0;
    height: 0;
    right: 2px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--blue-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 170;
}

.or-item-marker-rosa {
  position: absolute;
  width: 0;
  height: 0;
  right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgb(107, 55, 104);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;
}

.or-item-marker-rosa-start {
  position: absolute;
  width: 0;
  height: 0;
  right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgb(107, 55, 104);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;
}

.or-item-marker-rosa-end {
  position: absolute;
  width: 0;
  height: 0;
  right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgb(107, 55, 104);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;
}

.or-item-marker-text {
    right: 2px;
    transform: translate(7px, 0px);
    font-size: 8px;
    font-weight: 700;
    color: var(--bg-color-dark1);
}

.or-item-marker-text-rosa {
  right: 2px;
  transform: translate(7px, 0px);
  font-size: 8px;
  font-weight: 700;
  color: rgba(255, 156, 255);
}

.extension-border {
  position: absolute;
  right:0px;
  z-index: 20;
  flex-shrink: 0;
}
