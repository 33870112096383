.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--fg-color-bright);
}

.header {
  font-size: 12px;
  color: rgba(174, 176, 189, 1);
}

.sections-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
