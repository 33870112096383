/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.icon-container {
  width: 24px;
  margin-right: 10px;
  fill: var(--fg-color-bright);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
