/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color-dark1);
  overflow: hidden;
}

.sub-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  background-color: var(--bg-color-dark1);
}

.description {
  opacity: 0.5;
}

.duration {
  text-align: center;
  font-size: var(--medium-text);
}

