.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-right: 20px;
}

.diverse-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 37px;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
