/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
  height: 100%;
}

.container-for-header {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
  height: 100%;
  padding-top: 10px;
}

.title {
  font-size: x-large;
  font-weight: bold;
  margin-left: 24px;
  margin-bottom: 20px;
}
.title-for-header {
  font-size: x-large;
  font-weight: bold;
  margin-left: 24px;
}
.spacer {
  flex: 1;
}
