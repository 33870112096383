.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border-top: 1px solid #343B59;
  background-color: #121930;
  overflow: hidden;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  background-color: #121930;
  z-index: 50;
  flex-shrink: 0;
}

.content-scroll {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.content-animate {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  align-items: stretch;
}
