/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1.2em 1.2em 1.2em;
}
.container-folded {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1.2em 0.8em 1.2em;
}
.separator {
  border-top: 0.5px solid var(--fg-color-greyed);
}

.header-outer-container {
  display: block;
  position: sticky;
  top: -1.2em;
  z-index: 1;
  padding: 0.5em 0 0 0;
  background-color: var(--bg-color-dark1);
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-text-container {
  display: inline;
}
.header {
  font-size: normal;
  font-weight: bold;
  margin-right: 0.5em;
  background-color: var(--bg-color-dark1);
}
.header-count {
  color: var(--fg-color-greyed);
  font-size: normal;
  font-weight: bold;
  background-color: var(--bg-color-dark1);
}
.fold-button {
  height: 30px;
  width: 35px;
  padding: 0px;
  background-color: var(--bg-color-dark1);
}
.more-button {
  fill: var(--fg-color-greyed);
  stroke: var(--fg-color-greyed);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.less-button {
  fill: #ffffffb0;
  stroke: #ffffffb0;
  width: 48px;
  height: 48 px;
  flex-shrink: 0;
}
.shadow-bottom {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  content: '';
  height: 1em;
  background-image: linear-gradient(
    to top,
    rgba(var(--bg-color-dark1-rgb), 0),
    rgba(var(--bg-color-dark1-rgb), 1) 100%
  );
}

.completed-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.completed-text {
  color: var(--fg-color-frame);
}
.completed-icon {
  color: var(--fg-color-frame);
  font-size: 14pt;
}
.challenged-text {
  color: var(--fg-color-warning);
}
