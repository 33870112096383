.multiselect-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 45px;
  border: 1px solid #242a42;
  border-radius: 6px;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 15px;
}
