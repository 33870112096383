/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.pat-icon {
  fill: var(--fg-color-bright);
}
.icon {
  fill: #C7C7C7;
}
.id-icon {
  fill: var(--fg-color-bright);
  transform: translateY(1px);
}

.name {
  font-size: large;
}
.sub-meta-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.3em;
}
.sex-info {
  display: block;
  margin: 0;
  padding: 0;
}
.dob-info {
  margin-right: 0.3em;
}
.icon-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
}
