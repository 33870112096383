/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rooms-container {
  display: grid;
  /*!*flex-grow: 1;*!*/
  /*!*width: 100%;*!*/
  /*height: fit-content;*/
  /*!*overflow: auto;*!*/
  /*display: flex;*/
  /*flex-direction: row;*/
  /*flex-wrap: wrap;*/
  gap: 5px 40px;
  padding: 15px 15px 15px 30px;
  height: fit-content;
  overflow-x: hidden; /* Prevent horizontal overflow */
  overflow-y: auto; /* Allow vertical overflow */
}

.rooms-wrapper-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
}

.bed-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 5px;
}

.bed-container-single {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label {
  font-size: 1rem;
  color: #ffffff;
}

.infos {
  display: flex;
  flex-direction: column;
  width: 290px;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-left: 1px solid var(--border-color);
  padding: 20px;
  gap: 20px;
  background-color: #121930;
}

.room-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
  grid-row-gap: 5px;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
}

.single-bed-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /*border: 1px solid #303859;*/
  width: 50%;
}

.room-label {
  font-size: 12px;
  color: var(--fg-color-greyed);
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--fg-color-bright);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  /*padding-right: 20px;*/
}

.result {
  font-size: 14px;
  color: var(--fg-color-greyed);
  font-weight: bold;
}

.label-warning {
  color: #ffc000;
}

.pat-in-flight {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
