.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--fg-color-bright);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-warning {
  color: #FFC000;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  /*padding-right: 20px;*/
}

.result {
  font-size: 14px;
  color: var(--fg-color-greyed);
  font-weight: bold;
}
