/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2em 0;
  overflow-y: auto;
}

.title {
  font-size: x-large;
}

.download-link {
  display: none;
}
.download-container {
  margin: 32px auto 20px auto;
  width: 80%;
}
.download-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  padding: 8px var(--padding-std);
}
.download {
  width: 32px;
  height: 32px;
  fill: var(--fg-color-frame);
  transform: translateY(0px);
}
