/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-auto-flow: column;
  background-color: var(--bg-color-dark1);
  column-gap: 0.2em;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
}

.min-width {
  min-width: 290px;
  max-width: 400px;
}
