.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(18, 25, 48, 1);
  border: 1px solid rgba(48, 56, 89, 1);
  border-radius: 6px;
  padding: 18px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.info-pie-chart {
  display: flex;
  position: relative;
  height: 100%;
}

.key-value-pair {
  display: flex;
  width: 80px;
}

.pie-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
}

.table > tr {
  font-weight: bold;
}

.table td {
  border-bottom: 15px solid transparent;
}
