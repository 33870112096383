.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  border-bottom: 1px solid #303859;
}

.more-icon {
  position: absolute;
  right: -10px;
  cursor: pointer;
}

.more-icon-placeholder {
  width: 30px;
  height: 100%;
}

.stat-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.item-value {
  font-weight: 600;
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.title {
  width: 300px;
}
