/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/


:root{
  /*--line-height: 18px;*/
  --corner-radius: 4px;
  --xlarge-text: 24px;
  --large-text: 16px;
  --mlarge-text: 14px;
  --medium-text: 12px;
  --small-text: 10px;
  --text-weight: 600;
}

.container {
  /*border-radius: var(--corner-radius);*/
  /*border: 2px solid var(--blue-grey);*/
  /*background-color: #121930b0;*/
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  line-height: var(--line-height);
  overflow: hidden;
  height: 100%;
}

.container:hover {
  cursor: pointer;
}

.active-text {
  color: var(--fg-color-frame);
}
.completed {
  opacity: 0.5;
}
.completed-text {
  color: var(--fg-color-greyed);
}
.running {
  border: 3px solid orange;
}
.planned {
  border: 3px solid darkred;
}
.step-anesthesia {
  color: var(--fg-color-female);
}
.step-surgery {
  color: var(--fg-color-frame);
}
.challenged {
  color: var(--fg-color-warning);
}

.content-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}

.top-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.top-left-container {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  flex: 1;
  overflow-wrap: break-word;
  inline-size: 70px;
}
.top-right-container {
  flex-basis: auto;
  white-space: nowrap;
}

.group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.transfer-group-warning {
  color: var(--fg-color-warning);
}

.name-container {
  font-size: var(--large-text);
  font-weight: var(--text-weight);
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.name-sub-container {
  font-size: var(--small-text);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}
.sex {
  color: var(--fg-color-greyed);
  white-space: nowrap;
}
.dob {
  color: var(--fg-color-greyed);
  white-space: nowrap;
}
.duration-container {
  font-size: var(--mlarge-text);
  padding: 0 6px;
  flex-shrink: 0;
}
.duration {
  font-weight: var(--text-weight);
}
.description {
  font-size: var(--medium-text);
  font-weight: var(--text-weight);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.description-short {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.description-long {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.step-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.step {
  font-size: var(--mlarge-text);
  line-height: var(--line-height);
}

.team-container {
  font-size: var(--medium-text);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  display: flex;
  flex-direction: row;
}
.team-title {
  color: var(--fg-color-greyed);
  margin-right: 5px;
}

.spacer {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--blue-grey);
  opacity: 0.5;
  /*margin: 4px 0;*/
  margin-bottom: 4px;
  margin-top: 3px;
}

.stage-anesthesia {
  background: var(--female-light);
}
.stage-surgery {
  background: var(--frame-light);
}

.stage-normal {
  background: rgba(255, 255, 255, 0.08);
}

.stage-highlighted {
  padding: 8px;
  border-radius: 4px;
  margin: 6px 0;
}

.team-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: var(--fg-color-greyed);
}

.auto-scroll-name-container {
  padding: 8px 8px 0 8px;
}

.auto-scroll-py {
  padding: 0 2px 0 8px;
}

.auto-scroll-py-pb {
  padding: 0 2px 8px 8px;
}

.brake-word {
  word-break: break-word;
}

.surgicalWarn {
  white-space: nowrap;
}

.shortcut-command-text {
  color: white;
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.preliminary-description {
  font-size: 14px;
}
