
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #303859;
}

.chart-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px
}

.chart-sum {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;
  padding: 2px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: var(--fg-color-bright);
}

.blocked-bed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #303859;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.occupied-bed {
  border-radius: 4px;
  background-color: var(--bg-color-frame);
  width: 100%;
  height: 100%;
}

.free-bed {
  border: 1px solid #4F5A87;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.overflow-bed {
  border-radius: 4px;
  background-color: #FFC000;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  /*padding-right: 20px;*/
}

.result {
  font-size: 14px;
  color: var(--fg-color-greyed);
  font-weight: bold;
}

.label-warning {
  color: #FFC000;
}
