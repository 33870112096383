/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  gap: 15px;
  width: 100%;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 30px;
}

.metrics {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.grid-span-4 {
  grid-column: span 4;
  height: 250px;
}

.grid-span-3 {
  grid-column: span 3;
  height: 250px;
}

.grid-title {
  grid-column: span 4;
  height: fit-content;
  font-size: 12px;
  font-weight: 600;
}
