.container-charts {
  display: flex;
  flex-direction: column;
  gap: 4px;
}


.z-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
}

.cell {
    display: flex;
    align-items: center;
    gap: 8px;
}

.top-left {
    grid-column: 1;
    grid-row: 1;
}

.top-right {
    grid-column: 2;
    grid-row: 1;
}

.bottom-left {
    grid-column: 1;
    grid-row: 2;
}

.bottom-right {
    grid-column: 2;
    grid-row: 2;
}

.legend {
  width: 15px;
  height: 15px;
}

.count {
    color: var(--fg-color-greyed);
}
