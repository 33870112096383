/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.current-time {
  position: absolute;
  left: 0;
  color: var(--fg-color-frame);
  font-size: var(--xlarge-text);
  font-weight: var(--text-weight);
  line-height: var(--line-height);
  text-align: center;
  transform: translate(0, -50%);
  background-color: #090f2380;
}

