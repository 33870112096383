.container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 13px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.name-birthday {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.birthday {
  color: rgba(139, 140, 147, 1);
}

.chips-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.clock-chips-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.step-info {
  color: rgba(174, 176, 189, 1);
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
