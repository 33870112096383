/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
:root {
  --color-border-start: rgba(56, 63, 77, 0.3)
}


.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--color-border-start);
  border-bottom: 1px solid var(--color-border-start);
  font-size: 16px;
  color: var(--fg-color-greyed);
}

.container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-border-start);
  border-bottom: 1px solid var(--color-border-start);
  padding: 7px 4px;
  white-space: nowrap;
}


.info-card {
  background-color: var(--bg-dashboard-info-card);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.colored-title{
  color: var(--text-dashboard-info-card);
  font-size: 10px;
  min-height: 22px;
  white-space: nowrap; /* Ensure the text is on a single line */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show an ellipsis for overflow text */
}

.group-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.group-text {
  font-size: 16px;
  flex-shrink: 0;
  line-height: normal;
  display: flex;
  align-items: center;
  min-height: 20px; /* Adjust based on your design requirements */
}

.time-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.description {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  line-height: normal;
  white-space: nowrap; /* Ensure the text is on a single line */
  /*overflow: hidden; !* Hide overflow text *!*/
  /*text-overflow: ellipsis; !* Show an ellipsis for overflow text *!*/
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2px;
}

.description-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
