/* Set margin and padding to  zero (Browser have typically defaults different than zero */
/* Allows to make a full screen div */
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Set margin and padding to  zero (Browser have typically defaults different than zero */
  /* Allows to make a full screen div */
  margin: 0;
  padding: 0;

  color: var(--fg-color-bright); /* default text color */
}

body * {
  box-sizing: border-box;
}

html,
body {
  /* Full screen div */
  height: 100%;
  width: 100%;

  /* prevent bonce effect on iPhones */
  position: fixed;
  overflow: hidden;
}

a {
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Root Element to consume the whole view area (= body) */
#root {
  height: 100%;
  width: 100%;
}

:root {
  /* sqior defaults */
  --bg-color-dark1-rgb: 9, 15, 35; /* #090f23 */
  --bg-color-dark1: rgb(var(--bg-color-dark1-rgb));
  --bg-color-dark1-brighter: #121930b0;
  --bg-color-dark2: #26344d;
  --bg-color-dark3: #3a506b;
  --bg-color-frame: #1cade4;
  --bg-color-frame2: #177daa;
  --bg-color-bright: #ffffff;

  --fg-color-dark1: #090f23;
  --fg-color-dark2: #26344d;
  --fg-color-dark3: #3a506b;
  --fg-color-frame: #1cade4;
  --fg-color-frame-dark: #1cade480;
  --fg-color-bright: #ffffff;
  --fg-color-bright2: rgb(189, 189, 189);
  --fg-color-warning: #f9c80e;
  --fg-color-greyed: #ffffff7f;
  --fg-color-greyed-dark: #ffffff20;
  --fg-color-female: #ed4ca7;
  --fg-color-emergeny: rgba(245, 93, 93);

  --female-light: rgba(237, 76, 167, 0.08);
  --frame-light: rgba(28, 173, 228, 0.08);

  --traffic-light-green: #3cd04e;
  --traffic-light-yellow: #ffbb5c;
  --traffic-light-red: #e14747;

  --text-color: var(--fg-color-bright);
  --text-color-secondary: #c7c7c7;

  --padding-std: 0.8em;
  --padding-small: 0.3em;
  --radius-corner-std: 0.8em;
  --radius-corner-small: 0.3em;

  --line-height-desc: 180%;

  --outline-thickness-std: 2px;
  --outline-thickness-thin: 1px;

  --scrollbar-width: 5px;
  --scrollbar-background: rgba(255, 255, 255, 0.2);

  --border-color: #303859;
  --bg-dashboard-info-card: rgba(156, 97, 153, 0.2);
  --text-dashboard-info-card: #ED7BE8;

  user-select: none;
}

/* for Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-background) transparent;
}

/* for Edge, Chrome, iPhone */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-background);
  border-radius: 100px;
}

/* Some global css classes ... TODO: collect via modules */
.text-highlight {
  color: var(--fg-color-frame);
}
.text-greyed {
  color: var(--fg-color-greyed);
}
.text-warning {
  color: var(--fg-color-warning);
}
.text-hot {
  color: var(--fg-color-female);
}
.symbol {
  padding: 0 0.1em;
  font-family: 'GenderSymbols';
}

.sq-com-escalation {
  color: var(--fg-color-warning);
  font-weight: bold;
}
.sq-com-escalation-reason {
  color: var(--fg-color-warning);
}
