.container {
  display: flex;
  flex-direction: column;
  background: rgba(18, 25, 48, 1);
  border: 1px solid rgba(48, 56, 89, 1);
  border-radius: 6px;
  padding: 18px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  gap: 15px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.value-container {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.trend-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.percentage {
  font-size: 12px;
  font-weight: 500;
}

.percentage-description {
  font-size: 10.5px;
  font-weight: 500;
  color: rgba(81, 83, 94, 1);
}

.active {
  color: var(--fg-color-frame);
}

.inactive {
  color: rgba(81, 83, 94, 1);
}

.more-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.chart-position {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chart-wrapper {
  position: absolute;
  bottom: -30px;
  left: -45px;
  right: -45px;
}

.info-container {
  display: flex;
  flex-direction: column;
}

.value {
  display: flex;
  align-items: center;
}
